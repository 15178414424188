// src/ComingSoon.js
import React, { useEffect, useState } from "react";

const ComingSoon = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [darkMode, setDarkMode] = useState(true);
  const [showBetaAction, setShowBetaAction] = useState(null);

  useEffect(() => {
    document.title = "Zamakh";

    const targetDate = new Date("2025-01-01T00:00:00+05:30");

    const interval = setInterval(() => {
      const now = new Date();
      const nowIST = new Date(
        now.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      );
      const timeLeft = targetDate - nowIST;

      if (timeLeft < 0) {
        clearInterval(interval);
        return;
      }

      setCountdown({
        days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
      });

      const fifteenDays = 15 * 24 * 60 * 60 * 1000;
      const betaStartDate = targetDate.getTime() - fifteenDays;
      const betaEndDate = betaStartDate + 5 * 24 * 60 * 60 * 1000;

      if (nowIST.getTime() >= betaStartDate && nowIST.getTime() < betaEndDate) {
        setShowBetaAction("register");
      } else if (
        nowIST.getTime() >= betaEndDate &&
        nowIST.getTime() < targetDate.getTime()
      ) {
        setShowBetaAction("join");
      } else {
        setShowBetaAction(null);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  const handleEmailClick = () => {
    window.location.href =
      "mailto:contact@zamakh.com?subject=Interest in Zamakh&body=I'm interested in learning more about Zamakh!";
  };

  const handleBetaActionClick = () => {
    if (showBetaAction === "register") {
      window.location.href = "https://forms.office.com/r/wp8BDREMeY";
    } else if (showBetaAction === "join") {
      window.location.href = "https://beta.zamakh.com";
    }
  };

  return (
    <div style={darkMode ? styles.darkContainer : styles.lightContainer}>
      <button
        onClick={toggleTheme}
        style={styles.themeToggle}
        aria-label={darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
      >
        {darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
      </button>

      <h2 style={styles.subHeader}>Coming Soon!</h2>
      <p style={styles.description}>
        Stay tuned—our new product is launching soon,
        <br />
        and we can’t wait to share it with you!
      </p>

      <button
        onClick={handleEmailClick}
        style={styles.emailButton}
        aria-label="Contact Us"
      >
        Contact Us
      </button>

      {showBetaAction && (
        <button
          onClick={handleBetaActionClick}
          style={styles.betaButton}
          aria-label={
            showBetaAction === "register" ? "Register for Beta" : "Join Beta"
          }
        >
          {showBetaAction === "register" ? "Register for Beta" : "Join Beta"}
        </button>
      )}

      <div style={styles.countdown}>
        {Object.entries(countdown).map(([key, value]) => (
          <div key={key} style={styles.countdownItem}>
            <span style={styles.countdownNumber}>{value}</span>
            <span style={styles.countdownLabel}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  darkContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#121212",
    color: "#ffffff",
    textAlign: "center",
    padding: "0 20px",
  },
  lightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#ffffff",
    color: "#333333",
    textAlign: "center",
    padding: "0 20px",
  },
  themeToggle: {
    padding: "10px 15px",
    fontSize: "1rem",
    marginBottom: "20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#007bff",
    color: "#ffffff",
  },
  emailButton: {
    padding: "10px 15px",
    fontSize: "1rem",
    marginBottom: "20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#28a745",
    color: "#ffffff",
  },
  betaButton: {
    padding: "10px 15px",
    fontSize: "1rem",
    marginBottom: "20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#ffc107",
    color: "#ffffff",
  },
  subHeader: {
    fontSize: "2.5rem",
    margin: "1rem 0",
  },
  description: {
    fontSize: "1.5rem",
    margin: "1rem 0",
  },
  countdown: {
    display: "flex",
    justifyContent: "center",
    margin: "2rem 0",
    flexWrap: "wrap",
  },
  countdownItem: {
    margin: "0 1rem",
    textAlign: "center",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: "20px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    flex: "1 1 100px",
    minWidth: "80px",
  },
  countdownNumber: {
    fontSize: "3rem",
    fontWeight: "bold",
    color: "#28a745",
  },
  countdownLabel: {
    fontSize: "1.2rem",
  },

  "@media (max-width: 375px)": {
    themeToggle: {
      padding: "8px 12px",
      fontSize: "0.9rem",
    },
    emailButton: {
      padding: "8px 12px",
      fontSize: "0.9rem",
    },
    betaButton: {
      padding: "8px 12px",
      fontSize: "0.9rem",
    },
    subHeader: {
      fontSize: "2rem",
    },
    description: {
      fontSize: "1.2rem",
    },
    countdownNumber: {
      fontSize: "2.5rem",
    },
    countdownLabel: {
      fontSize: "1rem",
    },
    countdownItem: {
      padding: "15px",
    },
  },
};

export default ComingSoon;
